import { makeStyles, Box, Chip } from '@material-ui/core';
import { ListWrapper, Table } from 'Components';
import { useList } from 'Hooks';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  content: {
    width: '100%'
  },
  sidebar: {
    width: '400px',
    flexShrink: 0,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  description: {
    paddingBottom: theme.spacing(2),
    '& a': {
      color: theme.palette.primary.main
    }
  },
  listButton: {
    marginLeft: theme.spacing(1)
  },
  sidebarTitle: {
    marginBottom: theme.spacing(2)
  },
  backButton: {
    color: '#fff'
  },
  buttonContainer: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    }
  },
  prevBookingEvents: {
    background: '#e0e0e0'
  },
  statusSuccess: {
    background: '#3fb56e',
    color: '#fff'
  },
  statusInProcess: {
    background: '#eb9019',
    color: '#fff',
  },
  statusFailed: {
    background: '#ea2424',
    color: '#fff'
  }
}));

const DEFAULT_SORT_PARAMS = {
  sort_column: 'date',
  sort_direction: 'desc',
}


export const MyBooking = ({ fetch_url }) => {
  const classes = useStyles();

  const {
    pending, data, records_total, paginationParams, handleChangePagination,
  } = useList({ fetchUrl: fetch_url, sorting: DEFAULT_SORT_PARAMS });

  const badges = {
    paid: {
      text: 'Оплачено',
      className: classes.statusSuccess
    },
    payment_waiting: {
      text: 'Очікується оплата',
      className: classes.statusInProcess
    },
    not_paid: {
      text: 'Не оплачено',
      className: classes.statusFailed
    },
    wait_payment: {
      text: 'В обробці',
      className: classes.statusSuccess
    }
  }

  const tableConfig = [
    {
      title: 'Дата та час',
      accessor: 'date_and_time_period',
    },
    {
      title: 'Локацiя',
      accessor: 'location_name',
    },
    {
      title: 'Сервiс',
      accessor: 'booking_service_name',
    },
    {
      title: 'Iнструктор',
      accessor: 'booking_provider_name',
    },
    {
      title: 'Статус',
      accessor: 'paid',
      cellComponent: ({ status }) => {
        return (
          <Chip label={badges[status]?.text} size="small"
            className={badges[status]?.className} />
        )
      }
    },
  ];



  const rowClassFn = ({ upcoming }) => {
    if (!upcoming) {
      return classes.prevBookingEvents
    }
  }

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.content}>
          <ListWrapper title="Мої бронювання">
            <Table
              config={tableConfig}
              pending={pending}
              data={data}
              rowClassFn={rowClassFn}
              pagination={{ records_total, paginationParams, handleChangePagination }}
            />
          </ListWrapper>
        </Box>
      </Box>
    </>
  )
};

