import { makeStyles, Link } from '@material-ui/core';
import * as ClientPages from 'Pages/client';
import Header from './components/Header';
import { toPhoneNumberFormat } from 'Helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    color: 'rgba(0, 0, 0, 0.87)',
    flexDirection: 'column'
  },
  content: {
    flexGrow: 1,
    padding: `0 ${theme.spacing(4)}px 30px ${theme.spacing(1)}px`,
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px 20px',
    }
  },
  footer: {
    background: '#11698E',
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(1)}px`,
    [theme.breakpoints.down('xs')]: {
      padding: `5px ${theme.spacing(2)}px 10px ${theme.spacing(2)}px`,
      flexWrap: 'wrap'
    }
  },
  footerSide: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      order: '1',
    }
  },
  footerMidSide: {
    flexShrink: '0',
    [theme.breakpoints.down('xs')]: {
      order: '0',
      margin: '10px auto'
    }
  },
  footerLogo: {
    display: 'block',
    width: '158px',
    margin: '0 auto'
  },
  link: {
    color: '#fff'
  },
  infoTitle: {
    fontSize: '20px'
  },
  info: {
    color: '#fff',
    margin: 0
  },
  copyright: {
    background: '#11698E',
    textAlign: 'center',
    color: '#fff',
    padding: `${theme.spacing(2)}px`,
  },
  adress: {
    color: '#fff',
    fontSize: '8px',
    margin: '15px 0 0 0'
  }
}));


const Layout = ({
  children, footer_logo_url, logo_url, client_name, log_out_props,
  layout, layout_data, home_page_url, auth_url, my_booking_url, online_services_url,
  booking_types_url, booking_class_url, booking_spot_event_url, booking_url, company_base_settings, my_subscription_url
}) => {
  const classes = useStyles();

  const headerNavigation = {
    logo_url,
    home_page_url,
    auth_url,
    my_booking_url,
    online_services_url,
    booking_types_url,
    booking_class_url,
    booking_spot_event_url,
    booking_url,
    my_subscription_url
  }

  const clientData = {
    client_name,
    log_out_props,
  }

  const currentYear = new Date().getFullYear();

  return (
    <>
      <div className={classes.root}>
        <Header navigation={headerNavigation} clientData={clientData} />
        <main className={classes.content}>
          {!!layout &&
            React.createElement(ClientPages[layout], { layout_data, children })
          }
          {!layout && children}
        </main>
      </div>
      <section>
        <footer className={classes.footer}>
          <div className={classes.footerSide}>
            <p className={classnames(classes.infoTitle, classes.info)}>
              Служба пiдтримки:
            </p>
            {!!company_base_settings.phone && 
              <Link
                className={classes.link}
                href={`tel:${company_base_settings.phone}`}
              >
                {toPhoneNumberFormat(company_base_settings.phone)}
              </Link>
            }
            <br />
            {!!company_base_settings.work_time &&
              <p className={classes.info}>{`Робочий час: ${company_base_settings.work_time}`}</p>
            }
            {!!company_base_settings.adress && 
              <p className={classes.adress} dangerouslySetInnerHTML={{ __html: 'Юридична адреса: ' + company_base_settings.adress }}></p>
            }
          </div>
          <div className={classes.footerMidSide}>
            <img
              className={classes.footerLogo}
              src={footer_logo_url}
              alt=""
            />
            <div className={classnames(classes.copyright)}>
              <Link
                className={classes.link}
                href="https://gymsatellite.com"
                target="_blank"
              >
                gymsatellite.com
              </Link>
              © 2019-{currentYear}
            </div>

          </div>
          <div className={classes.footerSide}></div>
        </footer>

      </section>
    </>
  );
}

export default Layout;
