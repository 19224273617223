import {
  Paper,
  CssBaseline,
  Avatar,
  Typography,
  Button,
  makeStyles,
  Grid,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FormInput } from 'Components';
import { initI18n, transformLocales } from 'Helpers';
import { log_in } from 'Localization';
import 'Styles/application';

initI18n(transformLocales(log_in));

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    maxWidth: '600px',
    width: '100%',
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '200px',
    }
  },
  header: {
    textAlign: 'center',
  },
  formContainer: {},
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: `${theme.spacing(1)}px auto`,
    backgroundColor: theme.palette.secondary.main,
  },
  col: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    display: 'block',
    width: '100%',
    maxWidth: '280px',
    margin: '0 auto 16px'
  }
}));

const Login = ({ login_url, authenticity_token, logo_url, icon_url }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} xs={12} sm={4} md={7} className={classes.col}>
        <img className={classes.image} src={icon_url} />
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        className={classes.col}
        square
      >
        <div className={classes.paperer}>
          <div className={classes.header}>
            <img src={logo_url} className={classes.logo} />
            <Typography component="h1" variant="h5">
              {t('Sign In')}
            </Typography>
          </div>
          <form className={classes.form} action={login_url} method="post">
            <input
              type="hidden"
              name="authenticity_token"
              value={authenticity_token}
            />
            <FormInput
              name="user[email]"
              label={t('Email')}
              autoComplete="email"
              required
              autoFocus
            />
            <FormInput
              name="user[password]"
              label={t('Password')}
              type="password"
              required
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {t('Sign In')}
            </Button>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

document.addEventListener('DOMContentLoaded', () => {
  const props = JSON.parse(
    document.getElementById('page-content').getAttribute('data')
  );
  ReactDOM.render(<Login {...props} />, document.getElementById('page-root'));
});
