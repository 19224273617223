import { Typography, Box, Grid, Card, makeStyles } from '@material-ui/core';

import { OnlineServiceProps } from './types';

const useStyles = makeStyles((theme) => ({
  card: {
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '10px 20px',
    minHeight: '110px',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',

    '&:hover': {
      background: '#e4f9f8',
    },
  },
  service: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    textDecoration: 'none',
    color: 'inherit',
    textAlign: 'center',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  },
  serviceName: {
    textTransform: 'uppercase',
  },
  servicePrice: {
    color: '#11698E',
    fontSize: '1.3rem',
    textTransform: 'uppercase',
  },

  oldPrice: {
    color: theme.palette.text.disabled,
    textDecoration: 'line-through',
    fontSize: '0.8em',
  },
}));

export const OnlineService = ({ service, servicesUrl }: OnlineServiceProps) => {
  const classes = useStyles();
  const isPriceWithDiscount = service.price_with_discount != service.price;

  return (
    <Grid
      key={service.id}
      item
      xs={12}
      sm={12}
      md={6}
    >
      <Card
        elevation={8}
        className={classes.card}
      >
        <Box
          component="a"
          href={`${servicesUrl}${service.id}`}
          className={classes.service}
        >
          <Typography>
            <div
              className={classes.serviceName}
              dangerouslySetInnerHTML={{ __html: service.name }}
            ></div>
          </Typography>
          {isPriceWithDiscount && (
            <Typography>
              <strong className={classes.oldPrice}>
                {service.price} грн{service.subscription && '/місяць'}
              </strong>
            </Typography>
          )}
          <Typography>
            <strong className={classes.servicePrice}>
              {isPriceWithDiscount
                ? service.price_with_discount
                : service.price}{' '}
              грн{service.subscription && '/місяць'}
            </strong>
          </Typography>
        </Box>
      </Card>
    </Grid>
  );
};
