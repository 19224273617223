import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { ClientApplicationQueryProviderProps } from './types';

const queryClient = new QueryClient();

const ClientApplicationQueryProvider = (
  props: ClientApplicationQueryProviderProps
) => {
  const { children } = props;
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default ClientApplicationQueryProvider;
