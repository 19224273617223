import { Typography, Box, Grid, Card, makeStyles } from '@material-ui/core';
import { OnlineService } from './OnlineService';

const useStyles = makeStyles((theme) => ({
  cards: {
    height: '100%',
    flex: '1',
    marginRight: '24px',
    width: '75%',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      margin: `${theme.spacing(3)}px 0 `,
      width: '100%',
    },
  },
  info: {
    fontSize: '20px',

    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
}));

export const OnlineServices = ({ services, servicesUrl }) => {
  const classes = useStyles();

  return (
    <Box className={classes.cards}>
      <Grid
        container
        spacing={2}
      >
        {services.map((service) => (
          <OnlineService
            key={service.id}
            service={service}
            servicesUrl={servicesUrl}
          />
        ))}
      </Grid>
      {!services.length && (
        <p className={classes.info}>
          Немає клубних карток та абонементів по обраних фільтрах
        </p>
      )}
    </Box>
  );
};
