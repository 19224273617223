import { Box, Typography, makeStyles } from '@material-ui/core';

import { Button } from 'Components';

import { StartServiceSectionProps } from './types';

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2) + 'px',
    alignItems: 'flex-start',
  },
  startServiceNotUsed: {
    color: theme.palette.error.main,
  },
}));

export const StartServiceSection = ({
  startService,
  openBuyStartServiceDialog,
  openBuyOnlineServiceDialog,
}: StartServiceSectionProps) => {
  const classes = useStyles();

  const isStartServiceBought = !!startService.data;
  const isStartServiceAvailable = !!startService?.data?.available_till;

  return (
    <Box className={classes.flex}>
      <Box className={classes.flex}>
        <Button
          color="primary"
          size="large"
          onClick={openBuyStartServiceDialog}
          disabled={isStartServiceBought}
          endIcon={isStartServiceBought && <i className="icon-ok"></i>}
        >
          Стартовий платіж
        </Button>
        {!isStartServiceBought ? (
          <>
            <Typography>
              Для оформлення підписки, зробіть стартовий платіж.
            </Typography>
            {startService?.description && (
              <Typography>
                <div
                  dangerouslySetInnerHTML={{ __html: startService.description }}
                ></div>
              </Typography>
            )}
          </>
        ) : (
          <>
            <Typography>
              Стартовий платіж підтверджено.{' '}
              {!isStartServiceAvailable ? (
                <span className={classes.startServiceNotUsed}>
                  Для оформлення підписки зверніться до адміністратора.{' '}
                </span>
              ) : (
                `Оформлення підписки доступно до ${startService.data?.available_till} включно. 
                  Після ${startService.data?.available_till} оформлення підписки буде вимагати повторної оплати стартового платежу.`
              )}
            </Typography>
          </>
        )}
      </Box>
      <Button
        color="primary"
        size="large"
        onClick={openBuyOnlineServiceDialog}
        disabled={!isStartServiceBought || !isStartServiceAvailable}
      >
        Придбати підписку
      </Button>
    </Box>
  );
};
