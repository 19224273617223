import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl as MFormControl, makeStyles } from '@material-ui/core';
import {
  FormWrapper,
  Form,
  FormInput,
  FormControl,
  Checkbox,
  MultiSelect,
  TextEditor,
  Button,
  ServicesPickerDialog,
  ServicesTable,
  Select,
} from 'Components';
import { checkRequired, checkRequiredArray } from 'Helpers/validation';
import { AJAX } from 'Helpers';
import { useForm } from 'Hooks';

const validators = {
  location_ids: checkRequiredArray('location_ids'),
  order_position: checkRequired('order_position'),
  online_payment_key_id: checkRequired('online_payment_key_id'),
  start_service_id: (item) => {
    if (item.start_service_required) {
      return checkRequired('start_service_id')(item);
    }
    return null;
  },
  resubscribe_days: (item) => {
    if (item.start_service_required) {
      return checkRequired('resubscribe_days')(item);
    }
    return null;
  },
};

const useStyles = makeStyles(() => ({
  selectServicesBtn: {
    width: '50%',
  },
  settingsCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  formLabel: {
    fontSize: '0.75em',
  },
}));

const DEFAULT_RESUBSCRIBE_DAYS = '5';

const OnlineServiceForm = ({
  url,
  api_url,
  online_service,
  services_api_url,
  locations,
  online_payment_keys,
  start_services,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    item,
    changeValue,
    action,
    method,
    isItemInvalid,
    validationMessagesToDisplay,
    submit,
    setServerErrors,
    setItem,
  } = useForm(
    {
      ...online_service,
      ...{
        start_service_required:
          !!online_service.id && !!online_service.start_service_id
            ? true
            : false,
      },
    },
    validators,
    api_url
  );

  const [services, setServices] = useState([]);

  useEffect(() => {
    fetchServices();
  }, [item.location_ids]);

  const fetchServices = async () => {
    const { location_ids } = item;

    if (!location_ids.length) {
      return;
    }

    const res = await AJAX.get(services_api_url, {
      body: {
        filters: {
          locations: location_ids,
        },
      },
    });

    const { data } = res;

    if (data) {
      setServices(data);
    }
  };

  const servicesPicker = useRef(null);

  const onServicesDialogOpen = () => {
    servicesPicker.current.open(item.services, onServicesChange);
  };

  const onServicesChange = (selectedService) => {
    changeValue('services', selectedService);
  };

  const onServiceDelete = (serviceId) => {
    const services = item.services.filter(({ id }) => id !== serviceId);
    changeValue('services', services);
  };

  const onLocationIdsChange = ({ target }) => {
    setItem({
      ...item,
      location_ids: target.value,
      services: [],
    });
  };

  const onPaymentKeyChange = ({ target }) => {
    changeValue('online_payment_key_id', target.value);
  };

  const onOrderPositionChange = ({ target }) => {
    changeValue('order_position', target.value);
  };

  const onAchiveChange = ({ target }) => {
    changeValue('active', target.checked);
  };

  const onSubscriptionChange = ({ target }) => {
    changeValue('subscription', target.checked);
  };

  const onStartServiceRequiredChange = ({ target }) => {
    changeValue('start_service_required', target.checked);
  };

  const onStartServiceChange = ({ target }) => {
    changeValue('start_service_id', target.value);
  };

  const onDaysForResubscribeChange = ({ target }) => {
    if (isNaN(+target.value)) return;
    changeValue('resubscribe_days', target.value);
  };

  const descriptionTextEditor = useRef(null);
  const nameTextEditor = useRef(null);

  const getSerializedItem = () => {
    return {
      ...item,
      service_ids: item.services.map((service) => service.id),
      description: descriptionTextEditor.current.value,
      name: nameTextEditor.current.value,
    };
  };

  return (
    <>
      <Form
        name="online_service"
        action={action}
        method={method}
        validators={validators}
        disabled={isItemInvalid}
        item={item}
        onSubmit={submit}
        getSerializedItem={getSerializedItem}
        onError={setServerErrors}
      >
        <FormWrapper
          title="Online Service"
          item={item}
          backUrl={url}
        >
          <FormControl
            fullWidth
            label={<span className={classes.formLabel}>{t('Name')}</span>}
          >
            <TextEditor
              ref={nameTextEditor}
              value={item.name}
            />
          </FormControl>

          <MultiSelect
            options={locations}
            label={t('Locations')}
            onChange={onLocationIdsChange}
            value={item.location_ids}
            validationMessages={validationMessagesToDisplay.location_ids}
            required
          />
          {!!services.length && (
            <>
              <FormControl
                fullWidth
                label={
                  <span className={classes.formLabel}>{t('Services')}</span>
                }
                validationMessages={validationMessagesToDisplay.services}
              >
                <Button
                  color="primary"
                  className={classes.selectServicesBtn}
                  onClick={onServicesDialogOpen}
                >
                  {t('Select services')}
                </Button>
                <ServicesTable
                  services={item.services}
                  onServiceDelete={onServiceDelete}
                />
              </FormControl>
            </>
          )}
          <Select
            validationMessages={
              validationMessagesToDisplay.online_payment_key_id
            }
            value={item.online_payment_key_id || ''}
            options={online_payment_keys}
            label={t('Online Payment Key')}
            onChange={onPaymentKeyChange}
            name="online_service[online_payment_key_id]"
            required
          />
          <FormInput
            type="number"
            required
            name="online_service[order_position]"
            validationMessages={validationMessagesToDisplay.order_position}
            value={item.order_position}
            label={t('Position')}
            onChange={onOrderPositionChange}
          />

          <FormControl
            fullWidth
            label={
              <span className={classes.formLabel}>{t('Description')}</span>
            }
          >
            <TextEditor
              ref={descriptionTextEditor}
              value={item.description}
            />
          </FormControl>

          <MFormControl fullWidth>
            <Checkbox
              checked={item.active || false}
              onChange={onAchiveChange}
              label={t('Active')}
              name="online_service[active]"
            />
          </MFormControl>

          <MFormControl fullWidth>
            <Checkbox
              checked={item.subscription || false}
              onChange={onSubscriptionChange}
              label={t('Subscription')}
              name="online_service[subscription]"
            />
          </MFormControl>
          {!!item.subscription && (
            <MFormControl fullWidth>
              <Checkbox
                checked={item.start_service_required}
                onChange={onStartServiceRequiredChange}
                label={t('Start payment required')}
              />
            </MFormControl>
          )}
          {!!item.start_service_required && (
            <MFormControl fullWidth>
              <Select
                value={item.start_service_id || ''}
                options={start_services}
                label={t('Start service')}
                onChange={onStartServiceChange}
                name="online_service[start_service_id]"
                required={item.start_service_required}
                validationMessages={
                  validationMessagesToDisplay.start_service_id
                }
              />
              <FormInput
                type="text"
                value={item.resubscribe_days}
                label={t('Days for resubscribe')}
                onChange={onDaysForResubscribeChange}
                name="online_service[resubscribe_days]"
                required={item.start_service_required}
                validationMessages={
                  validationMessagesToDisplay.resubscribe_days
                }
              />
            </MFormControl>
          )}
        </FormWrapper>
      </Form>
      <ServicesPickerDialog
        ref={servicesPicker}
        services={services}
      />
    </>
  );
};

OnlineServiceForm.defaultProps = {
  online_service: {
    name: '',
    service_ids: [],
    location_ids: [],
    services: [],
    active: false,
    description: '',
    subscription: true,
    order_position: '',
    start_service_required: false,
    start_service_id: '',
    resubscribe_days: DEFAULT_RESUBSCRIBE_DAYS,
  },
};

export default OnlineServiceForm;
