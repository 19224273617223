export const OnlineServices = {
  en: {
    'Online Services': 'Online Services',
    Position: 'Position',
    Name: 'Name',
    Active: 'Active',
    Subscription: 'Subscription',
    Price: 'Price',
    'Create Online Service': 'Create Online Service',
    'Extra price': 'Extra price',
    'Discount or extra price for the first month of subscription':
      'Discount or extra price for the first month of subscription',
    'Online Payment Key': 'Online Payment Key',
  },
  ru: {
    'Online Services': 'Онлайн Сервисы',
    Name: 'Название',
    Active: 'Активный',
    Subscription: 'Подписка',
    Price: 'Цена',
    'Create Online Service': 'Создать онлайн сервис',
    Position: 'Позиция',
  },
  uk: {
    'Online Services': 'Онлайн Сервіси',
    Name: 'Назва',
    Active: 'Активний',
    Subscription: 'Підписка',
    Price: 'Ціна',
    'Create Online Service': 'Створити онлайн сервіс',
    Position: 'Позиція',
    'Extra price': 'Додаткова ціна',
    'Discount or extra price for the first month of subscription':
      'Знижка або доплата за перший місяць підписки',
    'Online Payment Key': 'Платіжний ключ',
  },
};

export const OnlineServiceForm = {
  en: {
    ...OnlineServices.en,
    'New Online Service': 'New Online Service',
    'Edit Online Service': 'Edit Online Service',
    'Update Online Service': 'Update Online Service',
    'Total Price': 'Total Price',
    Description: 'Description',
    UAH: 'UAH',
    'Online Payment Key': 'Online Payment Key',
    'Start payment required': 'Start payment required',
    'Start service': 'Start service',
    'Days for resubscribe': 'Days for resubscribe',
  },
  ru: {
    ...OnlineServices.ru,
    'New Online Service': 'Новый Онлайн Сервис',
    'Edit Online Service': 'Редактирование Онлайн Сервиса',
    'Update Online Service': 'Обновить Онлайн Сервис',
    'Total Price': 'Итоговая Цена',
    Description: 'Описание',
    UAH: 'грн.',
  },
  uk: {
    ...OnlineServices.uk,
    'New Online Service': 'Новий Онлайн Сервіс',
    'Edit Online Service': 'Редагування Онлайн Сервісу',
    'Update Online Service': 'Оновити Онлайн Сервіс',
    'Total Price': 'Загальна сума',
    Description: 'Опис',
    UAH: 'грн.',
    'Online Payment Key': 'Платіжний ключ',
    'Start payment required': 'Додати стартовий платіж',
    'Start service': 'Стартовий сервіс',
    'Days for resubscribe': 'Днів для повторної підписки',
  },
};
