import { useRef } from 'react';
import { Typography, makeStyles, Box, Container } from '@material-ui/core';

import { ListWrapper, Button } from 'Components';
import BuyDialog from './buyDialog';
import { StartServiceSection } from './start_service_section';

import { useStartSubscriptionServiceStatus } from 'queries';
import { Preloader } from 'Components';

const DEFAULT_REFETCH_INTERVAL = 1000;
const DEFAULT_REFETCH_COUNT = 1;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  content: {
    width: '100%',
  },
  sidebar: {
    width: '400px',
    flexShrink: 0,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  description: {
    padding: `${theme.spacing(2)}px 0`,
    '& a': {
      color: theme.palette.primary.main,
    },
  },
  listButton: {
    marginLeft: theme.spacing(1),
  },
  sidebarTitle: {
    marginBottom: theme.spacing(2),
  },
  backButton: {
    color: '#fff',
  },
  buttonContainer: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  preloader: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
}));

const OnlineService = ({
  id,
  name,
  price,
  subscription,
  description,
  new_client_online_order_url,
  back_url,
  start_service,
}) => {
  const classes = useStyles();

  const buyStartServiceDialog = useRef(null);
  const buyOnlineServiceDialog = useRef(null);

  const openBuyStartServiceDialog = async () => {
    buyStartServiceDialog.current.open();
  };

  const openBuyOnlineServiceDialog = async () => {
    buyOnlineServiceDialog.current.open();
  };

  const {
    data: startService,
    isLoading,
    isFetching,
  } = useStartSubscriptionServiceStatus({
    fetchUrl: start_service?.start_service_status_url,
    refetchOptions: {
      refetchInterval: DEFAULT_REFETCH_INTERVAL,
      refetchCount: DEFAULT_REFETCH_COUNT,
      subscription_id: id,
    },
  });

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.content}>
          <Container className={classes.buttonContainer}>
            <Button
              component="a"
              href={back_url}
              variant="contained"
              color="primary"
              size="small"
              className={classes.backButton}
              startIcon={<i className="icon-left-open"></i>}
            >
              Повернутись
            </Button>
          </Container>
          <ListWrapper
            title={<div dangerouslySetInnerHTML={{ __html: name }}></div>}
          >
            {isFetching && isLoading ? (
              <Box className={classes.preloader}>
                <Preloader />
              </Box>
            ) : (
              <Box className={classes.description}>
                {subscription && start_service ? (
                  <StartServiceSection
                    startService={{
                      ...startService,
                      description: start_service?.description || '',
                    }}
                    openBuyStartServiceDialog={openBuyStartServiceDialog}
                    openBuyOnlineServiceDialog={openBuyOnlineServiceDialog}
                  />
                ) : (
                  <Button
                    color="primary"
                    size="large"
                    onClick={openBuyOnlineServiceDialog}
                  >
                    Придбати
                  </Button>
                )}
                <p>
                  <Typography
                    variant="body1"
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></Typography>
                </p>
              </Box>
            )}
          </ListWrapper>
        </Box>
      </Box>
      {start_service &&
        (!startService?.data || !startService?.data?.available_till) && (
          <BuyDialog
            ref={buyStartServiceDialog}
            fetchUrl={start_service.new_client_online_order_url}
            name={start_service.name}
            price={start_service.price}
            subscription={false}
          />
        )}
      <BuyDialog
        ref={buyOnlineServiceDialog}
        fetchUrl={new_client_online_order_url}
        name={name}
        price={price}
        subscription={subscription}
      />
    </>
  );
};

export default OnlineService;
