import {
  Box,
  Button,
  Container,
  makeStyles,
  Typography,
} from '@material-ui/core';

import { ListWrapper } from 'Components';

import { SubscriptionForResubscribeType } from 'schemas';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  content: {
    width: '100%',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  listWrapper: {
    marginBottom: theme.spacing(2),
    padding: 0,
  },
  unSubscribeButton: {
    [theme.breakpoints.down('xs')]: {
      margin: `${theme.spacing(1)}px auto`,
      display: 'block',
    },
  },
  preloader: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  message: {
    color: theme.palette.primary.main,
  },
  listTitle: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '24px',
  },
  list: {
    background: '#e4f9f8',
    boxShadow: 'none',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    padding: theme.spacing(2),
  },
  cards: {
    width: '75%',
    margin: `${theme.spacing(1)}px auto`,
    [theme.breakpoints.down('sm')]: {
      margin: `${theme.spacing(3)}px 0 `,
      width: '100%',
    },
  },
  subscriptionInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subscriptionPrice: {
    textTransform: 'uppercase',
  },
}));

export const SubscriptionForResubscribe = ({
  subscription,
  services_url,
}: {
  subscription: SubscriptionForResubscribeType;
  services_url: string;
}) => {
  const classes = useStyles();

  return (
    <>
      <ListWrapper
        title="Доступні підписки"
        className={classes.list}
        titleClassName={classes.listTitle}
      >
        <Box key={subscription.id}>
          <div>
            <Box>
              <div className={classes.subscriptionInfo}>
                <Typography
                  variant="h5"
                  component="h5"
                  className={classes.subscriptionInfo}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: subscription.name,
                    }}
                  ></div>
                </Typography>
                <Typography
                  variant="h5"
                  component="h5"
                >
                  <span className={classes.subscriptionPrice}>
                    {subscription.price} грн/місяць
                  </span>
                </Typography>

                <div className={classes.buttonWrapper}>
                  <Button
                    className={classes.unSubscribeButton}
                    color="primary"
                    variant="contained"
                    href={`${services_url}/${subscription.id}`}
                  >
                    відновити
                  </Button>
                </div>
              </div>
            </Box>
          </div>
        </Box>
      </ListWrapper>
      <Container>
        <Typography
          variant="h6"
          component="p"
        >
          Кінцева дата дії сервісу: {subscription.service_end_date}
        </Typography>
        <Typography
          variant="h6"
          component="p"
        >
          Можливість відновлення без стартового платежу:{' '}
          {subscription.expired_date}
        </Typography>
      </Container>
      {subscription.description && (
        <Container>
          <Typography
            variant="h5"
            component="h5"
            className={classes.subscriptionInfo}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: subscription.description,
              }}
            ></div>
          </Typography>
        </Container>
      )}
    </>
  );
};
