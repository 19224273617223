import { useQuery } from '@tanstack/react-query';
import { HttpClientApplication } from 'network/HttpClientApplication';
import { StartSubscriptionServiceStatusParams } from 'schemas';

export const START_SUBSCRIPTION_SERVICE_STATUS_QUERY_KEY =
  'startSubscriptionServiceStatus';

export const useStartSubscriptionServiceStatus = ({
  fetchUrl,
  refetchOptions,
}: StartSubscriptionServiceStatusParams) => {
  return useQuery(
    [START_SUBSCRIPTION_SERVICE_STATUS_QUERY_KEY],
    () => {
      return HttpClientApplication.getStartSubscriptionServiceStatus(
        fetchUrl,
        refetchOptions.subscription_id
      );
    },
    {
      refetchOnWindowFocus: (query) => {
        if (query.state.data?.data) return false;

        query.state = { ...query.state, dataUpdateCount: 0 };
        return true;
      },
      keepPreviousData: false,
      enabled: !!fetchUrl,
      refetchInterval: (_, query) => {
        const { refetchCount, refetchInterval } = refetchOptions;
        if (!!query.state.data?.data) return false;

        return query.state.dataUpdateCount < refetchCount
          ? refetchInterval
          : false;
      },
    }
  );
};
