import { useQuery } from '@tanstack/react-query';
import { HttpClientApplication } from 'network/HttpClientApplication';
import { MySubscriptionInformationParams } from 'schemas';

export const MY_SUBSCRIPTION_INFORMATION_QUERY_KEY =
  'mySubscriptionInformation';

export const useMySubscriptionInformation = ({
  fetchUrl,
}: MySubscriptionInformationParams) => {
  return useQuery(
    [MY_SUBSCRIPTION_INFORMATION_QUERY_KEY],
    () => {
      return HttpClientApplication.getMySubscriptionInformation(fetchUrl);
    },
    {
      refetchOnWindowFocus: true,
      keepPreviousData: true,
    }
  );
};
