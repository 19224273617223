import {
  AppBar, Toolbar, Typography, Button, makeStyles, Box, Link, IconButton,
  Drawer, Divider, ListItemText, List, ListItem, ListItemIcon
} from '@material-ui/core';
import { ButtonWithMenu } from 'Components';
import { LocaleSwitcher } from 'Components/client';
import { CLIENT_APP_LOCALES_LIST } from 'Constants';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    paddingRight: 0,
    background: '#11698E',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    minHeight: '74px!important',
  },
  settingsButton: {
    width: '52px',
    height: '52px',
    color: '#fff'
  },
  navigationButton: {
    width: '100%',
    textAlign: 'left',
    justifyContent: 'flex-start',
  },
  headerWrapper: {
    justifyContent: 'space-between',
    padding: `6px ${theme.spacing(3)}px`,
    [theme.breakpoints.down('xs')]: {
      padding: `5px 0 5px ${theme.spacing(2)}px`,
    }
  },
  userName: {
    color: '#fff',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  logo: {
    display: 'block',
    width: '75px'
  },
  menuRightSide: {
    display: 'flex',
    alignItems: 'center',
  },
  localeSwitcher: {
    marginLeft: '16px'
  },
  menu: {
    display: 'flex',
    alignItems: 'center'
  },
  menuItem: {
    marginRight: '32px',
    fontSize: '16px',
    color: '#fff',
    textAlign: 'center',
  },
  accentMenuItem: {
    color: '#ffa200',
    fontWeight: 700,
  },
  currentPage: {
    paddingTop: '12px',
    paddingBottom: '12px',
    borderBottom: '2px solid rgb(255,255,255)',
  },
  logoWrapper: {
    marginRight: '30px'
  },
  closeMenuBtn: {
    color: '#fff'
  },
  menuItemIcon: {
    minWidth: '30px',
    color: '#fff'
  },
  hamburgerMenu: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    }
  },
  hiddenSm: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  marginRight: {
    marginRight: '16px',
  }
}));


export default function Header({ navigation, clientData }) {
  const classes = useStyles();
  
  const {
    logo_url, home_page_url, auth_url, my_booking_url, online_services_url,
    booking_types_url, booking_class_url, booking_spot_event_url, booking_url, my_subscription_url
  } = navigation;

  const { client_name, log_out_props } = clientData || {};

  const [isMenuOpened, changeMenuState] = React.useState(false)

  function handleOpenMenu() {
    changeMenuState(true)
  }

  function handleCloseMenu() {
    changeMenuState(false)
  }

  function isActiveLink(links) {
    return links.some((link) => window.location.href.includes(link))
  }

  function renderNavigation() {
    if (!client_name) {
      return (
        <>
          <ListItem
            button component="a"
            href={booking_types_url}
          >
            <ListItemText primary="Забронювати" />
          </ListItem>
          <ListItem
            button component="a"
            color='white'
            href={auth_url}
          >
            <ListItemText primary="Авторизуватися" />
          </ListItem>
        </>
      )
    }

    return (
      <>
        <ListItem
          button component="a"
          href={home_page_url}
        >
          <ListItemText primary="Мої відвідування" />
        </ListItem>
        <ListItem
          button
          component="a"
          href={my_booking_url}
        >
          <ListItemText primary="Мої бронювання" />
        </ListItem>
        <ListItem
          button
          component="a"
          href={my_subscription_url}
        >
          <ListItemText primary="Моя підписка" />
        </ListItem>
        <ListItem
          button
          component="a"
          href={booking_types_url}
        >
          <ListItemText primary="Забронювати" />
        </ListItem>
        <ListItem
          button
          component="a"
          href={online_services_url}
        >
          <ListItemText primary="Клубні картки та Абонементи" />
        </ListItem>
        <form
          method="post"
          action={log_out_props.url}
        >
          <input
            type="hidden"
            name="_method"
            value="delete"
          />
          <input
            type="hidden"
            name="authenticity_token"
            value={log_out_props.token}
          />
          <ListItem
            button
            component="button"
            type="submit"
          >
            <ListItemIcon className={classes.menuItemIcon}>
              <i className="icon-logout"></i>
            </ListItemIcon>
            <ListItemText primary="Вийти" />
          </ListItem>
        </form>
      </>
    )
  }

  return (
    <>
      <AppBar
        position="fixed"
        className={classes.appBar}
      >
        <Toolbar
          className={classes.headerWrapper}
          disableGutters
        >
          <Box className={classes.menu}>
            <a
              href={home_page_url}
              className={classnames(classes.menuItem, classes.logoWrapper)}
            >
              <img
                className={classes.logo}
                src={logo_url}
                alt="logo"
              />
            </a>
            {!!client_name && (
              <>
                <Link
                  href={home_page_url}
                  className={classnames(classes.menuItem, classes.hiddenSm,
                    { [classes.currentPage]: isActiveLink([home_page_url]) })}
                  underline='none'
                >
                  Мої відвідування
                </Link>
                <Link
                  href={my_booking_url}
                  className={classnames(classes.menuItem, classes.hiddenSm,
                    { [classes.currentPage]: isActiveLink([my_booking_url]) })}
                  underline='none'
                >
                  Мої бронювання
                </Link>
                <Link
                  href={my_subscription_url}
                  className={classnames(classes.menuItem, classes.hiddenSm,
                    { [classes.currentPage]: isActiveLink([my_subscription_url]) })}
                  underline='none'
                >
                  Моя підписка
                </Link>
              </>
            )}
            <Button
              href={booking_types_url}
              variant="contained"
              color="primary"
              component="a"
              className={classnames(classes.hiddenSm, classes.marginRight)}
            >
              
              Забронювати
            </Button>
            {!!client_name && (
              <Button
                href={online_services_url}
                variant="contained"
                color="primary"
                component="a"
                className={classes.hiddenSm}
              >
                  Клубні картки та Абонементи
              </Button>
            )}
          </Box>
          <Box className={classes.menuRightSide}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleOpenMenu}
              className={classes.hamburgerMenu}
            >
              <i className="icon-menu"></i>
            </IconButton>
            {!client_name && (
              <Button
                href={auth_url}
                variant="contained"
                color="primary"
                component="a"
                className={classes.hiddenSm}
              >
                Авторизуватися
              </Button>
            )}
            {client_name && (
              <>
                <Typography className={classnames(classes.userName, classes.hiddenSm)}>
                  Вiтаю, {client_name}
                </Typography>
                <Box className={classes.hiddenSm}>
                  <ButtonWithMenu trigger={
                    <IconButton className={classes.settingsButton}>
                      <i className="icon-cog"></i>
                    </IconButton>
                  }>
                    <Box>
                      <form
                        method="post"
                        action={log_out_props.url}
                      >
                        <input
                          type="hidden"
                          name="_method"
                          value="delete"
                        />
                        <input
                          type="hidden"
                          name="authenticity_token"
                          value={log_out_props.token}
                        />
                        <Button
                          type="submit"
                          size="medium"
                          className={classes.navigationButton}
                          startIcon={<i className="icon-logout"></i>}
                        >
                          Вийти
                        </Button>
                      </form>
                    </Box>
                  </ButtonWithMenu>
                </Box>
              </>
            )}
            {/* will be implementer leter */}
            {/* <LocaleSwitcher className={classes.localeSwitcher} locales={CLIENT_APP_LOCALES_LIST} /> */}
          </Box>
        </Toolbar>
      </AppBar>
      {/* mobile menu */}
      <Drawer
        anchor="right"
        open={isMenuOpened}
        onClose={handleCloseMenu}
        PaperProps={{
          style: {
            background: '#11698E',
            color: '#fff',
            minWidth: '240px'
          }
        }}
      >
        <Box>
          <IconButton
            onClick={handleCloseMenu}
            className={classes.closeMenuBtn}
          >
            <i className="icon-cancel"></i>
          </IconButton>
          <Divider />
          <List>
            {renderNavigation()}
          </List>
        </Box>
      </Drawer>
      <div className={classes.toolbar}></div>
    </>
  )
}
