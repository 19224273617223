import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import cn from 'classnames';

const useStyles = makeStyles({
  problemPaymentStatus: {
    color: 'red',
    whiteSpace: 'pre-line',
  },
});

const WAIT_PAYMENT_PAYMENT_STATUS = 'wait_payment';

const PAYMENT_STATUS = {
  [WAIT_PAYMENT_PAYMENT_STATUS]: 'Wait payment',
  success: 'Success',
  in_process: 'In process',
  added_by_user: 'Added by user',
};

export const PaymentStatusCell = ({ payment_status, liqpay_payment_id }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const isWaitPaymentPaymentStatus =
    payment_status === WAIT_PAYMENT_PAYMENT_STATUS;

  const liqpayPaymentId = isWaitPaymentPaymentStatus
    ? `,\n${liqpay_payment_id}`
    : '';

  return (
    <p
      className={cn({
        [classes.problemPaymentStatus]: isWaitPaymentPaymentStatus,
      })}
    >
      {t(PAYMENT_STATUS[payment_status]) + liqpayPaymentId}
    </p>
  );
};
