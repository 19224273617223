import { AJAX } from 'Helpers';
import { MySubscriptionInformationResponseData } from 'schemas';

export class HttpClientApplication {
  static getStartSubscriptionServiceStatus(
    fetchUrl: string,
    subscription_id: number
  ) {
    if (!fetchUrl) return { data: null };

    return AJAX.get(fetchUrl, {
      body: {
        subscription_id,
      },
    });
  }

  static getMySubscriptionInformation(
    fetchUrl: string
  ): MySubscriptionInformationResponseData {
    return AJAX.get(fetchUrl);
  }
}
